<template>
  <div class="Wrap">
    <div class="content">
      <img class="bg" src="../assets/images/indexBgsn.jpg" alt="" />


       <div class="btn" @click="clickHandle">
      <img src="../assets/images/toWx.png" alt="" />
      <!-- <div
        style="position: absolute; top: 0; left: 0; width: 65vw; height: 100%"
        v-html="xcxHTML"
      ></div> -->
    </div>
    <div class="text" @click="copy">
      <img src="../assets/images/shortText.png" alt="" />
    </div>
    </div>
   
  </div>
</template>

<script>
// @ is an alias to /src

import { getWeAppCode } from "@/util/index.js";
import axios from "axios";
export default {
  name: "Snyym",
  components: {},
  data() {
    return {
      xcxHTML: "",
      isTip: false,
      openLink: "",
      id: "",
    };
  },
  mounted() {
    this.xcxHTML = getWeAppCode();
this.id = Math.floor(Math.random()*3000000000)
  // this.id=this.getQueryString("id")
    if (!this.isMobile()) {
      axios
        // .get("https://yymsntest.makebuluo.cn/api/yym/sendNoteToUser?id="+this.id)
        .get("https://ymsn.makebuluo.cn/api/yym/sendNoteToUser?id="+this.id)
        .then((data) => {
          this.openLink = data.data.data;
        });
    }
  },
  methods: {
    //获取用户id
    getQueryString(name) {
      // var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      // var r = window.location.search.substr(1).match(reg);
      // if (r != null) return unescape(r[2]);
      // return null;
    },

    isMobile() {
      if (
        window.navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
      ) {
        // 移动端
        return false;
      } else {
        // PC端
        // this.isTip = true;
        return true;
      }
    },
    clickHandle() {
      location.href = this.openLink;
    },
    copy() {
     var test = window.location.href
      const str =test;
      const input1 = document.createElement("input");
      document.body.appendChild(input1);
      input1.value = str;
      input1.setAttribute("readOnly", "readOnly");
      input1.select();
      document.execCommand("Copy");
      document.body.removeChild(input1);
      alert("复制成功");
    },
  },
  created(){
    document.title="一起守护我的喵"
  }
};
</script>
<style lang="less" scoped>
.Wrap {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y:auto ;
  background-color: #e2e8f8;
  .content {
    width: 100%;
    height: 100%;
    position: relative;
    .bg {
      margin-top: 5px;
      width: 100vw;
     
      // object-fit: cover;
    }
  }
}
.btn,
.text {
  width: 200px;
  position: absolute;
  top: 625px;
  left: 50%;
  transform: translate(-50%, 0);
}
.text {
  width: 208px;
  top: 700px;
}



</style>