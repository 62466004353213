import Vue from 'vue'
import App from './App.vue'
import router from './router'
import wx from '@/util/wxShare';

Vue.config.productionTip = false;


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')



// wx.setWXApiConfig(
//   "http://roche.zitzolab.com/wechat/wechat/share/JsShare.php?url=" + encodeURIComponent(location.href),
//   (e) => {
//     wx.setShare("Roche", "Roche", location.href, "http://roche.zitzolab.com/shareimg/shareImg.jpg");
//     //隐藏所有非基础按钮接口
//     // wx.hideAllNonBaseMenuItem()
//   }
// );
