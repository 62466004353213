
import wx from 'weixin-js-sdk';
import axios from 'axios'

var root = (typeof window !== 'undefined' ? window : (typeof process === 'object' && typeof require === 'function' && typeof global === 'object') ? global : this);

//微信分享模块对象
root.WechatSDKModel = {};
var WechatSDKModel = root.WechatSDKModel;

var _getAbsoluteATag = document.createElement('A');

/**
 * 获取绝对路径
 * @param url
 * @return {*}
 */
function getAbsoluteUrl(url) {
    if (url.indexOf("http:") >= 0 || url.indexOf("https:") >= 0) return url;
    _getAbsoluteATag.href = url;
    url = _getAbsoluteATag.href;
    return url;
}

/**
 * 获取默认值
 * @param obj
 * @param defaultValue
 * @return {*}
 */
function getDefault(obj, defaultValue) {
    if (obj === undefined || obj === null) return defaultValue;
    if (obj === 'true') return true;
    else if (obj === 'false') return false;
    return obj;
}

//分享接口地址
var WXApiConfigUrl = '';
//使用jssdk的功能列表
var jsApiList = [
    'checkJsApi',
    'onMenuShareTimeline',
    'onMenuShareAppMessage',
    'onMenuShareQQ',
    'onMenuShareWeibo',
    'hideMenuItems',
    'showMenuItems',
    'hideAllNonBaseMenuItem',
    'showAllNonBaseMenuItem',
    'translateVoice',
    'startRecord',
    'stopRecord',
    'onRecordEnd',
    'playVoice',
    'pauseVoice',
    'stopVoice',
    'uploadVoice',
    'downloadVoice',
    'chooseImage',
    'previewImage',
    'uploadImage',
    'downloadImage',
    'getNetworkType',
    'openLocation',
    'getLocation',
    'hideOptionMenu',
    'showOptionMenu',
    'closeWindow',
    'scanQRCode',
    'chooseWXPay',
    'openProductSpecificView',
    'addCard',
    'chooseCard',
    'openCard',
    'wx-open-launch-weapp'
];

//默认分享成功
var shareTitle = "";
var shareContent = "";
var shareUrl = window.location.href;
var shareImgUrl = "";
var shareSuccessCallBack = undefined;
var shareCancelCallBack = undefined;
var shareFailCallBack = undefined;

//发送给朋友
var shareFriendTitle = "";
var shareFriendContent = "";
var shareFriendUrl = "";
var shareFriendImgUrl = "";
var shareFriendSuccessCallBack = undefined;
var shareFriendCancelCallBack = undefined;
var shareFriendFailCallBack = undefined;

//发送给朋友圈
var shareTimelineTitle = "";
var shareTimelineContent = "";
var shareTimelineUrl = "";
var shareTimelineImgUrl = "";
var shareTimelineSuccessCallBack = undefined;
var shareTimelineCancelCallBack = undefined;
var shareTimelineFailCallBack = undefined;

// 微信分享类型
var shareTypes = {
    //发送给好友
    appmessage: "appmessage",
    //分享到朋友圈
    timeline: "timeline",
    //分享到微博
    weibo: "weibo",
    //分享到qq
    qq: "qq",
    //分享到facebook
    facebook: "facebook"
};

//设置分享内容
WechatSDKModel.setShare = setShare;
function setShare(title, content, url, imgUrl, success, cancel, fail) {

    title = shareTitle = getDefault(title, '设置分享标题');
    content = shareContent = getDefault(content, '设置分享内');
    url = shareUrl = getDefault(url, window.location.href);
    imgUrl = shareImgUrl = getDefault(imgUrl, getAbsoluteUrl('./images/ShareImg.jpg'));
    shareSuccessCallBack = success;
    shareCancelCallBack = cancel;
    shareFailCallBack = fail;

    shareFriendTitle = shareTitle;
    shareFriendContent = shareContent;
    shareFriendUrl = shareUrl;
    shareFriendImgUrl = shareImgUrl;
    // shareFriendSuccessCallBack = success;
    // shareFriendCancelCallBack = cancel;
    // shareFriendFailCallBack = fail;

    shareTimelineTitle = shareTitle;
    shareTimelineContent = shareContent;
    shareTimelineUrl = shareUrl;
    shareTimelineImgUrl = shareImgUrl;
    // shareTimelineSuccessCallBack = success;
    // shareTimelineCancelCallBack = cancel;
    // shareTimelineFailCallBack = fail;

    if (WXApiConfigUrl !== "") bindJsApiEvent();
    else {
        $("body").prepend("<img class='sharePic' src='" + imgUrl + "' style='position:fixed;top: -1000px;left: -1000px;' />");
        $(document).attr('title', title);
        if (url.indexOf("#") >= 0) window.location.hash = url.substring(url.indexOf("#"));
    }

}

//分享给朋友
WechatSDKModel.shareToFriend = shareToFriend;
function shareToFriend(title, content, url, imgUrl, success, cancel, fail) {
    shareFriendTitle = title;
    shareFriendContent = content;

    if (url) shareFriendUrl = getAbsoluteUrl(url);
    if (shareFriendUrl === '') shareFriendUrl = shareUrl;

    if (imgUrl) shareFriendImgUrl = getAbsoluteUrl(imgUrl);
    if (shareFriendImgUrl === '') shareFriendImgUrl = shareImgUrl;

    shareFriendSuccessCallBack = success;
    shareFriendCancelCallBack = cancel;
    shareFriendFailCallBack = fail;

    if (WXApiConfigUrl !== "") bindJsApiEvent();
}

//分享到朋友圈
WechatSDKModel.shareToTimeline = shareToTimeline;
function shareToTimeline(title, content, url, imgUrl, success, cancel, fail) {

    shareTimelineTitle = title;
    shareTimelineContent = content;

    if (url) shareTimelineUrl = getAbsoluteUrl(url);
    if (shareTimelineUrl === '') shareTimelineUrl = shareUrl;

    if (imgUrl) shareTimelineImgUrl = getAbsoluteUrl(imgUrl);
    if (shareTimelineImgUrl === '') shareTimelineImgUrl = shareImgUrl;

    shareTimelineSuccessCallBack = success;
    shareTimelineCancelCallBack = cancel;
    shareTimelineFailCallBack = fail;

    if (WXApiConfigUrl !== "") bindJsApiEvent();

}


WechatSDKModel.hideAllNonBaseMenuItem = function (arr = []) {
    wx.hideAllNonBaseMenuItem();
}

WechatSDKModel.setWXApiConfig = setWXApiConfig;

/**
 * 进行微信分享API Config请求
 * @param {string} url  
 */
function setWXApiConfig(url, fn) {

    if (WXApiConfigUrl !== "") return;
    WXApiConfigUrl = url;
    //不是绝对路径

    axios.post(url,
        {},
        { withCredentials: false }).then(e => {
            // console.log()
            // wx.config(eval(e.data.replace(" wx.config", '')))
            e.data=eval('(' + e.data + ')');
            // console.log(e.data.data)
            // e.data.data.openTagList = ['wx-open-launch-weapp'];
            e.data.jsApiList = jsApiList;
            // console.log(e.data.data)
            wx.config(e.data)
            fn && fn()
        })
}

//绑定微信SKD事件
WechatSDKModel.bindJsApiEvent = bindJsApiEvent;

function bindJsApiEvent() {
    //微信SDK API设置
    wx.checkJsApi({
        // 需要检测的JS接口列表，所有JS接口列表见附录2,
        jsApiList: jsApiList,
        success: function (res) {
            // 以键值对的形式返回，可用的api值true，不可用为false
            // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
        }
    });
    //2.1 监听“分享给朋友”，按钮点击、自定义分享内容及分享结果接口
    wx.onMenuShareAppMessage({
        title: shareFriendTitle,
        desc: shareFriendContent,
        link: shareFriendUrl,
        imgUrl: shareFriendImgUrl,
        trigger: function (res) {
        },
        success: function (res) {
            if (shareSuccessCallBack) shareSuccessCallBack(shareTypes.appmessage);
            if (shareFriendSuccessCallBack) shareFriendSuccessCallBack(shareTypes.appmessage);
        },
        cancel: function (res) {
            if (shareCancelCallBack) shareCancelCallBack(shareTypes.appmessage);
            if (shareFriendCancelCallBack) shareFriendCancelCallBack(shareTypes.appmessage);
        },
        fail: function (res) {
            if (shareFailCallBack) shareFailCallBack(shareTypes.appmessage);
            if (shareFriendFailCallBack) shareFriendFailCallBack(shareTypes.appmessage);
        }
    });

    // 2.2 监听“分享到朋友圈”按钮点击、自定义分享内容及分享结果接口
    wx.onMenuShareTimeline({
        title: (shareTimelineContent !== '') ? shareTimelineContent : shareTimelineTitle,
        link: shareTimelineUrl,
        imgUrl: shareTimelineImgUrl,
        trigger: function (res) {
            //alert('用户点击分享到朋友圈');
        },
        success: function (res) {
            if (shareSuccessCallBack) shareSuccessCallBack(shareTypes.timeline);
            if (shareTimelineSuccessCallBack) shareTimelineSuccessCallBack(shareTypes.timeline);
        },
        cancel: function (res) {
            if (shareCancelCallBack) shareCancelCallBack(shareTypes.timeline);
            if (shareTimelineCancelCallBack) shareTimelineCancelCallBack(shareTypes.timeline);
        },
        fail: function (res) {
            if (shareFailCallBack) shareFailCallBack(shareTypes.timeline);
            if (shareTimelineFailCallBack) shareTimelineFailCallBack(shareTypes.timeline);
        }
    });

    // 2.3 监听“分享到QQ”按钮点击、自定义分享内容及分享结果接口
    wx.onMenuShareQQ({
        title: shareTitle,
        desc: shareContent,
        link: shareUrl,
        imgUrl: shareImgUrl,
        trigger: function (res) {
            //alert(JSON.stringify(res));
        },
        complete: function (res) {
            //alert(JSON.stringify(res));
        },
        success: function (res) {
            if (shareSuccessCallBack) shareSuccessCallBack(shareTypes.qq);
        },
        cancel: function (res) {
            if (shareCancelCallBack) shareCancelCallBack(shareTypes.qq);
        },
        fail: function (res) {
            if (shareFailCallBack) shareFailCallBack(shareTypes.qq);
        }
    });

    // 2.4 监听“分享到微博”按钮点击、自定义分享内容及分享结果接口
    wx.onMenuShareWeibo({
        title: shareTitle,
        desc: shareContent,
        link: shareUrl,
        imgUrl: shareImgUrl,
        trigger: function (res) {
            //alert(JSON.stringify(res));
        },
        complete: function (res) {
            //alert(JSON.stringify(res));
        },
        success: function (res) {
            if (shareSuccessCallBack) shareSuccessCallBack(shareTypes.weibo);

        },
        cancel: function (res) {
            if (shareCancelCallBack) shareCancelCallBack(shareTypes.weibo);
        },
        fail: function (res) {
            if (shareFailCallBack) shareFailCallBack(shareTypes.weibo);
        }
    });

}

wx.error(function (res) { });
wx.ready(function () { bindJsApiEvent(); });

export default WechatSDKModel